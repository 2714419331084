import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../loader'; // Assuming you have a loading spinner component
import { toast } from 'react-toastify';
import { addMukhiyas } from '../../actions/mukhiyasAction'; // Assuming you have an API action for adding Mukhiya
import SelectName from '../family-tree/select-name'; // Assuming you have a component to select the name
import SelectGautra from '../family-tree/select-gautra'; // Assuming you have a component to select the gautra
import SelectVillage from '../family-tree/select-village'; // Assuming you have a component to select the village

const MukhiyaForm = ({ heading = 'Add Mukhiya', buttonText = 'Add Mukhiya', onMukhiyaAdded }) => {
  const [name, setName] = useState('');
  const [gautraObj, setGautraObj] = useState({ gautra: '', subcaste: '' });
  const [village, setVillage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (name) setErrorMsg('');
  }, [name]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!name || !gautraObj.gautra || !village) {
      setErrorMsg('Please fill in all required fields');
      return;
    }

    // Prepare Mukhiya data
    const mukhiyaData = {
      mukhiya_name: name.trim(),
      gautra: gautraObj.gautra.trim(),
      subcaste: gautraObj.subcaste.trim(),
      village: village.trim(),
    };

    setLoading(true);

    try {
      // Call API to add Mukhiya
      const response = await addMukhiyas(mukhiyaData);
      if (response.status) {
        toast.success('Mukhiya added successfully!');
        onMukhiyaAdded(response.mukhiya); // Notify parent component with new Mukhiya data
      } else {
        toast.error('Failed to add Mukhiya');
      }
    } catch (error) {
      console.error('Error adding Mukhiya:', error);
      toast.error('An error occurred while adding the Mukhiya');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <h2>{heading}</h2>

          <label>
            Mukhiya Name:
            <SelectName setName={setName} name={name} autoFocus={true} />
            {errorMsg && !name ? <div style={{ color: 'red' }}>{errorMsg}</div> : null}
          </label>

          <br />

          <label>
            Gautra with Subcaste:
            <SelectGautra setGautraObj={setGautraObj} gautraObj={gautraObj} />
            {errorMsg && !gautraObj.gautra ? <div style={{ color: 'red' }}>{errorMsg}</div> : null}
          </label>

          <br />

          <label>
            Village:
            <SelectVillage setVillage={setVillage} village={village} />
            {errorMsg && !village ? <div style={{ color: 'red' }}>{errorMsg}</div> : null}
          </label>

          <br />

          <button type="submit">{buttonText}</button>
        </form>
      )}
    </div>
  );
};

export default MukhiyaForm;
