import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getAllGautras2 } from '../../actions/gautraAction';
import Fuse from 'fuse.js';
import { useData } from '../../Context/context';

const SelectGautra = ({ setGautraObj, gautraObj, disabled, type = null, isOnlyGautra = false }) => {
  const { gautraData, setGautraData } = useData();
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (gautraData && gautraData.length > 0) {
      if (isOnlyGautra) {
        let optionDataSet = new Set();

        gautraData.forEach((val) => {
          const obj = JSON.parse(val.value);
          optionDataSet.add(getGautraDropDownOption(obj.gautra, null));
        });
        // Convert the Set back to an array
        let optionData = Array.from(optionDataSet);
        setOptions(optionData);
      } else setOptions(gautraData); // Use the data from context
      setLoading(false);
    } else {
      setLoading(true);
      const getGautras = async () => {
        try {
          const res = await getAllGautras2();
          const data = [];
          res.data.gautras.map((val) => {
            if (val.subcastes.length == 0) {
              data.push(getGautraDropDownOption(val.display_name, null));
            } else {
              val.subcastes.map((sc) => {
                data.push(getGautraDropDownOption(val.display_name, sc.display_name));
              });
            }
          });
          setOptions(data);
          setGautraData(data);
          setLoading(false);
        } catch (err) {
          console.log('Could not fetch gautras', err);
          setLoading(false);
          // toast.error(`Could not fetch gautras\n Error: ${err}`);
        }
      };
      getGautras();
    }
  }, [isOnlyGautra]);

  const searchParam = {
    keys: ['label'],
  };

  const fuse = new Fuse(options, searchParam);

  const searchResult = (inputValue) => {
    return new Promise((resolve) => {
      const res = fuse.search(inputValue).map((result) => result.item);
      resolve(res);
    });
  };

  const getGautraDropDownOption = (gautra, subcaste) => {
    const obj = {
      label: subcaste ? `${subcaste} (${gautra})` : gautra,
      value: JSON.stringify({ gautra, subcaste }),
    };
    // console.log(gautra, subcaste)
    return obj;
  };
  let win_variable = '';
  if (typeof document !== 'undefined') {
    win_variable = document.body;
  }

  return (
    <>
      {/* {type == 'creatable' ?
        (<AsyncCreatableSelect
          onChange={(option) => {
            if (option) {
              setGautraObj(JSON.parse(option?.value));
            }
            else {
              setGautraObj(option)
            }
          }
        }
          // defaultValue={gautra ? { label: gautra.label, value: gautra.value } : null}
          defaultValue={gautraObj? getGautraDropDownOption(gautraObj.gautra, gautraObj.subcaste): null}
          // value={gautra ? { label: gautra.label, value: gautra.value } : null}
          value={gautraObj? getGautraDropDownOption(gautraObj.gautra, gautraObj.subcaste): null}
          placeholder="Select Gautra:"
          isLoading={isLoading}
          cacheOptions
          defaultOptions={options}
          isClearable
          maxMenuHeight={150}
          menuPlacement="auto"
          loadOptions={searchResult}
          isDisabled={disabled}
          styles={{ option: (style) => ({ ...style, color: 'black' }) }}
        />)
        : */}
      <AsyncSelect
        onChange={(option) => {
          console.log(option);
          if (option) {
            setGautraObj(JSON.parse(option?.value));
          } else {
            setGautraObj(option);
          }
        }}
        // defaultValue={gautra ? { label: gautra, value: gautra } : null}
        defaultValue={gautraObj ? getGautraDropDownOption(gautraObj.gautra, gautraObj.subcaste) : null}
        placeholder=""
        // value={gautra ? { label: gautra, value: gautra } : null}
        value={gautraObj ? getGautraDropDownOption(gautraObj.gautra, gautraObj.subcaste) : null}
        isLoading={isLoading}
        cacheOptions
        defaultOptions={options}
        isClearable
        maxMenuHeight={150}
        menuPlacement="bottom"
        loadOptions={searchResult}
        isDisabled={disabled}
        menuPortalTarget={win_variable ?? <></>}
        styles={{
          option: (style) => ({ ...style, color: 'black' }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
    </>
  );
};

export default SelectGautra;
