import React, { useEffect, useState } from 'react';
import { getVillagesList } from '../../actions/villageAction';
import AsyncCreatableSelect from 'react-select/async-creatable';
// import Fuse from 'fuse.js';
import { toast } from 'react-toastify';
import { koot_falan, searchResult } from '../../helpers/utils';
import { useData } from '../../Context/context';

const SelectVillage = ({ setVillage, village }) => {
  const { villageData, setVillageData } = useData();
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [firstTenOptions, setFirstTenOptions] = useState(options.slice(0, 10));

  useEffect(() => {
    if (villageData && villageData.length > 0) {
      setOptions(villageData);
      setFirstTenOptions(villageData.slice(0, 10));
      setLoading(false);
    } else {
      setLoading(true);
      const getVillage = async () => {
        try {
          const res = await getVillagesList();
          let data = res.map((item) => {
            return { value: item.trim(), label: item.trim(), kootfalan: koot_falan(item) };
          });
          setOptions(data);
          setFirstTenOptions(data.slice(0, 10));
          setVillageData(data);
          setLoading(false);
        } catch (err) {
          console.log('Could not fetch villages', err);
          setLoading(false);
          // toast.error(`Could not fetch villages\n Error:${err}`);
        }
      };
      getVillage();
    }
  }, []);

  let win_variable = null;
  if (typeof document !== 'undefined') {
    win_variable = document.body;
  }

  //
  // const fuse = new Fuse(options, searchParam);

  // const searchResult = (inputValue) => {
  //   return new Promise((resolve) => {
  //     const res = fuse.search(inputValue).map((result) => result.item);
  //     resolve(res);
  //   });
  // };

  const searchOptions = (inputValue) => {
    return searchResult(options, inputValue.trim());
  };

  return (
    <AsyncCreatableSelect
      onChange={(option) => setVillage(option?.value.trim())}
      defaultValue={village ? { label: village, value: village } : null}
      value={village ? { label: village.trim(), value: village.trim() } : null}
      isLoading={isLoading}
      cacheOptions
      defaultOptions={firstTenOptions}
      loadOptions={searchOptions}
      placeholder=""
      isClearable
      maxMenuHeight={150}
      menuPlacement="auto"
      menuPortalTarget={win_variable ?? <></>}
      styles={{
        option: (style) => ({ ...style, color: 'black' }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
};

export default SelectVillage;
