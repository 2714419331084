import { getApiWrapper, postApiWrapper } from "./token-wrapper-function.js";

export const getVillages = async () => {
  const result = await getApiWrapper(`/api/report/villages_users_data?`);
  return result;
}

export const updateVillage = async (data) => {
  const response = await postApiWrapper(`/api/village/edit`, data);
  return response;
};

export const getUserInfo = async (name) => {
  const bodyData = {
    action: 'get_village_residents_list',
    village_name: name,
  };
  const response = await postApiWrapper(`/api/tasks/run_actions.php`, bodyData);
  return response;
};

export const getVillagesList = async () => {
  const response = await getApiWrapper(`/api/users/list/villages`);
  return response;
};
