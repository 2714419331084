import { getApiWrapper, postApiWrapper } from './token-wrapper-function';

export const getNamesByGender = async (gender) => {
  const result = await getApiWrapper(`/api/users/list/names?gender=${gender}`);
  return result;
};
export const updateNames = async (data) => {
  const result = await postApiWrapper(`/api/update/field/name`, data);
  return result;
};
