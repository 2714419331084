import { getApiWrapper, postApiWrapper } from './token-wrapper-function.js';

export const getAllGautras = async (callback) => {
  const gautraResponse = await getApiWrapper(`/api/gautras`);
  return gautraResponse;
};

export const postGautra = async (gautraData) => {
  const postGautraResponse = await postApiWrapper('/api/gautras', gautraData);
  return postGautraResponse;
};

export const editGautraAPI = async (gautraData, gautraId) => {
  const editGautraResponse = await postApiWrapper(`/api/gautras/${gautraId}`, gautraData);
  // const jsonResponse = editGautraResponse.json();
  return editGautraResponse;
  // if (editGautraResponse) {
  //   callback(editGautraResponse);
  // }
};

export const moveGautraAPI = async (gautraData) => {
  const moveGautraResponse = await postApiWrapper(`/api/gautras/move`, gautraData);
  return moveGautraResponse;
};

export const deleteGautraAPI = async (id) => {
  const deleteGautraResponse = await deleteApiWrapper(`/api/gautras/${id}`);
  return deleteGautraResponse;
};

export const getAllGautras2 = async () => {
  const jsonResponse = await getApiWrapper(`/api/gautras`);
  return jsonResponse;
};
