import { deleteApiWrapper, getApiWrapper, postApiWrapper, putApiWrapper } from './token-wrapper-function.js';
export const getMukhiyas = async (graphNodeId) => {
  const response = await getApiWrapper(`/api/users/${graphNodeId}/mukhiyas`);
  return response;
};
export const addMukhiyas = async (graphNodeId, searchparams) => {
  const url = `/api/users/${graphNodeId}/add_mukhiya`;
  const response = await postApiWrapper(url, searchparams);
  return response;
};
export const deleteMukhiya = async (graphNodeId, mukhiyaId) => {
  const url = `/api/users/${graphNodeId}/delete_mukhiya/${mukhiyaId}`;
  const response = await deleteApiWrapper(url);
  return response;
};
export const updateMukhiya = async (graphNodeId, mukhiyaId, updatedData) => {
  const url = `/api/users/${graphNodeId}/update_mukhiya/${mukhiyaId}`;
  const response = await postApiWrapper(url, updatedData);
  return response;
};
