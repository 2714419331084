import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getNamesByGender } from '../../actions/nameAction';
import Fuse from 'fuse.js';
import { koot_falan, searchResult } from '../../helpers/utils';
import { useData } from '../../Context/context';
import { toast } from 'react-toastify';

const SelectName = ({ setName, gender, name = '', autoFocus = false }) => {
  const { maleNamesData, setMaleNamesData, femaleNamesData, setFemaleNamesData } = useData();
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [firstTenOptions, setFirstTenOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let data;
      let setData;
      if (gender === 'male') {
        data = maleNamesData;
        setData = setMaleNamesData;
      } else if (gender === 'female') {
        data = femaleNamesData;
        setData = setFemaleNamesData;
      }

      if (data && data.length > 0) {
        // If namesData exists in context, use it directly
        setOptions(data);
        setFirstTenOptions(data.slice(0, 10));
      } else {
        // Otherwise, fetch namesData
        setLoading(true);
        try {
          const res = await getNamesByGender(gender);
          const newData = res.map((item) => ({
            value: item.trim(),
            label: item.trim(),
            kootfalan: koot_falan(item.trim()),
          }));
          setOptions(newData);
          setFirstTenOptions(newData.slice(0, 10));
          if (gender === 'male') {
            setMaleNamesData(newData);
          } else if (gender === 'female') {
            setFemaleNamesData(newData);
          }
          setLoading(false);
        } catch (e) {
          console.log('Could not fetch names', e);
          setLoading(false);
          toast.error(`Could not fetch names\n, Error:${e}`);
        }
      }
    };

    fetchData();
  }, [gender]);

  const searchParam = {
    keys: ['label'],
  };

  const fuse = new Fuse(options, searchParam);

  const searchOptions = (inputValue) => {
    return searchResult(options, inputValue.trim());
  };

  let win_variable = '';
  if (typeof document !== 'undefined') {
    win_variable = document.body;
  }

  return (
    <AsyncCreatableSelect
      onChange={(option) => setName(option?.value.trim())}
      defaultValue={name ? { label: name, value: name } : null}
      value={name ? { label: name, value: name } : null}
      isLoading={isLoading}
      autoFocus={autoFocus}
      cacheOptions
      defaultOptions={firstTenOptions}
      loadOptions={searchOptions}
      placeholder=""
      isClearable
      maxMenuHeight={150}
      menuPlacement="bottom"
      menuPortalTarget={win_variable ?? <></>}
      styles={{
        option: (style) => ({ ...style, color: 'black' }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
};

export default SelectName;
